import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Scroll controller connected");
    this.isUserScrolling = false;

    // Ensure the initial scroll after content is fully loaded or swapped
    setTimeout(() => {
      this.scrollToBottom({ force: true });
    }, 100); // Short delay to allow Turbo to finish rendering

    // Listen for Turbo frame load events
    document.addEventListener("turbo:frame-load", this.handleFrameLoad.bind(this));

    // Observe DOM mutations for new messages
    this.observeMutations();

    // Track user scrolling to avoid interfering
    this.element.addEventListener("scroll", () => this.trackUserScrolling());
  }

  handleFrameLoad(event) {
    if (event.target.id === "messages") {
      console.log("Messages frame loaded, scrolling to bottom");
      this.scrollToBottom({ force: true });
    }
  }

  scrollToBottom({ force = false } = {}) {
    if (force || !this.isUserScrolling) {
      // Directly set scrollTop to scrollHeight to scroll to the bottom
      this.element.scrollTop = this.element.scrollHeight;
      console.log("Scrolled to bottom directly");
    }
  }

  trackUserScrolling() {
    const { scrollTop, scrollHeight, clientHeight } = this.element;
    this.isUserScrolling = scrollTop + clientHeight < scrollHeight - 10;
    console.log("User scrolling detected:", this.isUserScrolling);
  }

  observeMutations() {
    const observer = new MutationObserver(() => {
      console.log("New message detected, scrolling...");
      this.scrollToBottom({ force: false });
    });

    observer.observe(this.element, {
      childList: true,
      subtree: true,
    });

    this.observer = observer; // Save reference to disconnect later
  }

  disconnect() {
    this.element.removeEventListener("scroll", this.trackUserScrolling);
    document.removeEventListener("turbo:frame-load", this.handleFrameLoad.bind(this));
    if (this.observer) this.observer.disconnect();
  }
}
