import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "input"];

  connect() {
    console.log("Chat controller connected");
  }

  clearForm(event) {
    // Ensure the form is reset after submission
    if (this.hasInputTarget) {
      this.inputTarget.value = "";
    }
  }
}
